.main {
  display: flex;
}

.app_bar {
  background-color: white !important;
  box-shadow: none !important;
  min-height: 60px !important;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) !important;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.toolbar_content {
  display: flex;
  align-items: center;
  gap: 70px;
}

.drawer_btn {
  margin-right: 1rem;
  color: #1f1f1f !important;
}

.drawer_icon {
  margin: 20px 0 10px 0;
  width: 150px;
}

.drawer_items {
  color: #1a2635 !important;
  text-decoration: none !important;
}
.drawer_items div {
  text-align: center !important;
}

.nav_link,
.nav_link_active {
  font-size: 14px !important;
  text-transform: none !important;
  text-decoration: none !important;
  color: #4c4c4c !important;
  margin: 0px 10px;
}

.nav_link_active {
  color: #00818c !important;
  font-family: 'SoehneBold' !important;
}

.nav_link_active span {
  position: relative;
}
.nav_link_active hr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin-top: 22px;
  border: 1.5px solid #00818c;
  border-radius: 4px;
}

.drawer_paper {
  box-sizing: border-box !important;
  width: 240px !important;
}

.search_input {
  border-radius: 32px !important;
  border: 1px solid #f4f4f4 !important;
  height: 45px !important;
  padding: 14px 17px !important;
  width: 250px !important;
  color: #292d32 !important;
  background-color: #fafafa !important;
}

.search_input::after,
.search_input::before {
  display: none !important;
}

.input_adornment {
  color: #292d32 !important;
  cursor: pointer !important;
}

.configrations {
  display: flex !important;
  align-items: center !important;
  gap: 14px !important;
}

.help_btn {
  color: #292d32 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
  cursor: pointer !important;
}

.icon_divider {
  border: 1px solid #c7c7c7 !important;
  height: 24px !important;
}

.alerts_icon {
  cursor: pointer !important;
  color: #292d32 !important;
  width: 24px !important;
  height: 24px !important;
}

.alert {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -8px;
  top: -8px;
  background: #fd7964;
  border-radius: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.notifications {
  position: relative;
}

.notificationList {
  position: absolute;
  top: 30px;
  z-index: 3;
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(137, 137, 137, 0.08);
  border-radius: 8px;
  width: 380px;
  left: -185px;
  padding: 16px;
  max-height: 460px;
  overflow: auto;
}

.listHeight {
  max-height: 460px;
  overflow: auto;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: 'SoehneBold' !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #1f1f1f;
}

.markAsRead {
  color: #00818c;
  cursor: pointer;
}

.divider {
  border: 1px solid #ededed;
  margin-top: 24px !important;
}

.single {
  margin-top: 24px;
}

.box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.boxContent {
  margin-left: 12px;
  margin-right: 30px;
}

.content {
  line-height: 24px !important;
  color: #1f1f1f;
  width: 247px;
}

.viewButton {
  font-style: normal;
  font-size: 12px !important;
  line-height: 16px !important;
  text-decoration-line: underline;
  color: #00818c;
  margin-top: 7px !important;
  cursor: pointer;
}

.contentUser {
  font-weight: 700;
}

.date {
  font-size: 12px !important;
  line-height: 20px !important;
  color: #7a8081;
}

.contractBox {
  padding: 12px;
  gap: 8px;
  width: 348px;
  height: 72px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.contract {
  font-family: '"Copyright Klim Type Foundry", sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1f1f1f;
  width: 232px;
  margin-right: 8px;
}

.dueDate {
  font-family: '"Copyright Klim Type Foundry", sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fd7964;
}

.profile_btn {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 45px !important;
  padding: 4px !important;
  background: #fafafa !important;
  border: 1px solid #f4f4f4 !important;
  border-radius: 32px !important;
  cursor: pointer !important;
  min-width: 180px !important;
  color: #1f1f1f !important;
}

.profile_btn svg {
  font-size: 30px !important;
}

.profile_btn_content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
}

.profile_avatar {
  background-color: grey !important;
  font-size: 16px;
}

.username {
  color: #1f1f1f !important;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: 4px;
}

.pricing_tier {
  color: #9d1600 !important;
  font-size: 8px !important;
  line-height: 12px !important;
  background: #fff2f0 !important;
  border-radius: 100px !important;
  width: fit-content !important;
  padding: 2px 4px !important;
}

.mobile_search_icon {
  color: #292d32 !important;
  cursor: pointer !important;
  display: none !important;
}

.menu {
  border-radius: 8px !important;
  padding: 0px 8px !important;
}

.menu_item,
.logout_btn {
  background: white !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.menu_item:hover {
  background: #e7f8f8 !important;
  border-radius: 4px !important;
  color: #00818c !important;
}

.logout_btn {
  color: #fd7964 !important;
}
.logout_btn:hover {
  background: #fd7964 !important;
  border-radius: 4px !important;
  color: white !important;
}

.search_modal {
  display: none;
}

@media (min-width: 1100px) {
  .drawer_btn,
  .drawer {
    display: none !important;
  }

  .logo {
    display: flex;
  }

  .nav_items {
    display: flex;
  }
}

@media (max-width: 1100px) {
  .logo,
  .nav_items {
    display: none !important;
  }

  .drawer {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .search_input,
  .help_btn,
  .icon_divider {
    display: none !important;
  }
  .mobile_search_icon,
  .search_modal {
    display: block !important;
  }
}

@media (max-width: 420px) {
  .mobile_search_icon,
  .alerts_icon {
    display: none !important;
  }
}

.loginBtn {
  color: '#00818C';
  border: '1px solid #00818C';
  padding: 8px 16px;
  gap: 10px;
  border: 1px solid #00818c;
  border-radius: 100px;
}
