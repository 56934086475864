/* .container {
  margin-top: 44px !important;
} */

/* .table {
  margin-top: 25px !important;
} */

.bgPagination {
  background-color: red;
}

.paginationRow {
  display: flex;
  margin-top: '40px';
}
