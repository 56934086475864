@font-face {
  font-family: 'SoehneRegular';
  src: local('SoehneRegular'),
    url('./assets/fonts/soehne-buch.woff2') format('woff2');
}

@font-face {
  font-family: 'SoehneMedium';
  src: local('SoehneMedium'),
    url('./assets/fonts/soehne-halbfett.woff2') format('woff2');
}

@font-face {
  font-family: 'SoehneBold';
  src: local('SoehneBold'),
    url('./assets/fonts/soehne-dreiviertelfett.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Copyright Klim Type Foundry', sans-serif;
  color: #002129;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

fieldset {
  border: 1px solid #eeeeee !important;
}

input::placeholder {
  color: #aeaeae;
}

@media (max-width: 800px) {
  .App {
    display: none !important;
  }
  .resize-image {
    width: 100%;
    height: 50%;
    object-fit: cover;
  }
  .resize-app {
    z-index: 1000;
    background: #000;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('./assets/imgs/uppershape.svg'),
      url('./assets/imgs/lowershape.svg');
    background-position: top right, bottom left;
  }

  .resize-window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background: #f4d7d4;
    border-radius: 20px;
  }

  .resize-window-header {
    background: #f4d7d4;
    text-align: center;
    padding: 5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
  }

  .resize-window-description {
    padding: 5%;
    background: #306ebb;
    text-align: center;
    color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .resize-window-header h3 {
    font-size: 30px;
    font-family: 'Noto Serif', serif !important;
    font-weight: bold;
  }

  .resize-window-description p {
    font-family: 'Proxima Nova' !important;
  }

  .resize-window-header img {
    margin-right: 2em;
  }

  .gc-section {
    display: none;
  }
}

@media (min-width: 801px) {
  .resize-app {
    display: none !important;
  }

  .css-nvq9cu-MuiTypography-root {
    font-family: '"Copyright Klim Type Foundry", sans-serif';
  }

  .placeholderStyleHint::placeholder {
    font-size: 14px;
    color: #b3b3b3;
    font-family: 'SoehneRegular', sans-serif;
  }

  .react-input-emoji--container {
    margin: 0 !important;
    padding: 12px 16px !important;
    gap: 10px !important;
    width: 394px !important;
    height: 56px !important;
    background: #f9f9f9 !important;
    border: 1px solid #eeeeee !important;
    border-radius: 4px !important;
    margin-top: 8px !important;
  }

  .react-input-emoji--wrapper {
    height: 100% !important;
  }

  .react-input-emoji--input {
    padding: 5px 0 !important;
    height: 90% !important;
  }

  .react-input-emoji--placeholder {
    left: 0 !important;
  }
}

#form-file-upload {
  border-radius: 4px;
  display: flex;
  position: relative;
  gap: 8px;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}
