.container {
  min-height: 100vh;
}

.heading {
  margin-top: 20px;
}

.button_container {
  padding-top: 20px;
  height: 10vh;
}

.main_container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset_box {
  padding: 30px 30px;
  width: 550px;
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.02),
    0px 16px 32px rgba(0, 0, 0, 0.02), 0px 8px 16px rgba(0, 0, 0, 0.02),
    0px 4px 8px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02),
    1px 0px 2px rgba(0, 0, 0, 0.02);
}

.verify_box {
  padding: 30px 30px;
  width: 550px;
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.02),
    0px 16px 32px rgba(0, 0, 0, 0.02), 0px 8px 16px rgba(0, 0, 0, 0.02),
    0px 4px 8px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02),
    1px 0px 2px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-size: 32px !important;
  font-family: 'SoehneBold' !important;
  margin-bottom: 15px !important;
}

.form {
  margin-top: 20px;
}

.reset_btn {
  padding: 10px 24px !important;
  margin-top: 25px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: 'SoehneMedium' !important;
}

.login {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

.login_btn {
  color: black !important;
}

@media (max-width: 580px) {
  .reset_box {
    min-width: 80%;
  }
}
