.container {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.grid,
.grid_item_1,
.grid_item_2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.grid_item_2 {
  flex-direction: column !important;
  align-items: flex-start !important;
}

.heading {
  font-family: "SoehneBold" !important;
  font-size: 48px !important;
  line-height: 70px !important;
  margin-bottom: 20px !important;
}

.subheading {
  font-size: 18px !important;
  line-height: 30px !important;
  margin-bottom: 20px !important;
}

.home_btn {
  width: 162px !important;
  box-shadow: none !important;
  height: 48px !important;
}

.link {
  text-decoration: none;
}

@media (max-width: 900px) {
  .container {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .heading {
    text-align: center !important;
    font-size: 38px !important;
    line-height: 44px !important;
  }
  .subheading {
    text-align: center !important;
  }

  .grid_item_1 img {
    width: 200px;
  }

  .grid_item_2 {
    align-items: center !important;
  }
}
